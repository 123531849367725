import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "./userSlice";

import config from "../config/config.json";
import { isTokenExpired } from "../utils/authUtils";




const baseQueryWithTokenCheck = async (args, api, extraOptions) => {
  const { getState, dispatch } = api;
  const token = getState().user.token;

  // Log the request details
  console.log("Request:", args);

  if (token && isTokenExpired(token)) {
    console.log(isTokenExpired(token));
    console.log("expired");

    dispatch(logout());
  }

  
  const baseQuery = fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  return baseQuery(args, api, extraOptions);
};



export const apiSlice = createApi({
  reducerPath: "api", 
  baseQuery: baseQueryWithTokenCheck,
  tagTypes: [
    "Banners",
    "Images",
    "Images-tag",
    "Sections",
    "topics",
    "topicDescriptions",
    "allTopics",

    "Questions",
    "QuestionsBySearch",
    "QuestionSolutionDescription",


    "ExamDetails",
    "Exam",
    "News",
    "NewsSection",
    "Events",
    "EventsSection",
    "EventsBySearch",

    "ExamModelSetSection",
    "ExamModelSet",

    "payment",
    "paymentDetails",

    "user",
    "SectionByCategorieId",
    "SectionByTopicId",
    "Category",
  ],
  endpoints: (builder) => ({}),
});
